/**
 * Dataset-specific key used when determining whether to
 * automatically open instructions in workview.
 */
export const viewedInstructionsOnDataset = ({ id }: { id: number }): string =>
  `viewed_instructions_on_dataset:${id}`

/**
 * Key used to store "view data as folders" setting on data tab
 */
export const WORKFLOW_FOLDER_ENABLED = 'workflowFolderEnabled'

/**
 * Returns key used to store the "toggle measures" layer bar setting in workview
 */
export const SHOULD_RENDER_MEASURES = 'shouldRenderMeasures'

/**
 * Returns key used to store the "toggle subannotations" layer bar setting in workview
 */
export const SHOULD_RENDER_SUB_ANNOTATIONS = 'shouldRenderSubAnnotations'

/**
 * Key used to store "default video annotation duration" in workview
 */
export const ANNOTATION_ITEM_DEFAULT_DURATION = 'annotationItemDefaultDuration'

/**
 * Used as total steps to jump when moving to previous or next frame
 */
export const VIDEO_FRAME_JUMP_STEP = 'videoFrameJumpStep'

/**
 * Show/hide audio waveform in the timeline for videos with audio
 */
export const AUDIO_WAVEFORM_VISIBLE = 'audioWaveformVisible'

/**
 * Key used to store 'hardwareConcurrency' in workview
 */
export const HARDWARE_CONCURRENCY = 'hardwareConcurrency'

/**
 * Key used to store "playback speed" in workview
 */
export const VIDEO_PLAYBACK_SPEED = 'videoPlaybackSpeed'

/**
 * Key used to store "sync video playback" in workview
 */
export const SYNC_VIDEO_PLAYBACK = 'syncVideoPlayback'

/**
 * Key used to store "video playback loop" in workview
 */
export const VIDEO_PLAYBACK_LOOP = 'videoPlaybackLoop'

/**
 * Key used to store 'neighbourTiles' in workview
 */
export const NEIGHBOUR_TILES = 'neighbourTiles'

/**
 * Key used to store "do not show again out of view dialog" in workview
 */
export const DO_NOT_SHOW_AGAIN_OUT_OF_VIEW_DIALOG = 'doNotShowAgainOutOfViewDialog'

/**
 * Key used to store "do not show again auto-track enablement dialog open" in workview
 */
export const DO_NOT_SHOW_AGAIN_AUTO_TRACK_DIALOG = 'doNotShowAgainAutoTrackingDialog'

/**
 * Key used to store "do not show again crosshairs tool dialog" in workview
 */
export const DO_NOT_SHOW_AGAIN_CROSSHAIRS_TOOL_DIALOG = 'doNotShowAgainCrosshairsToolDialog'

/**
 * Key used to store "rad view gallery collapsed" in workview
 */
export const RAD_VIEW_GALLERY_COLLAPSED = 'radViewGalleryCollapsed'

/**
 * Key used to store "annotations page size" in workview for Dev configs
 */
export const ANNOTATIONS_PAGE_SIZE = 'annotationsPageSize'

/**
 * Key used to store "frames preloading size" in workview for Dev configs
 */
export const FRAMES_PRELOAD_SIZE = 'framesPreloadSize'

/**
 * Key used to store "video timeline gallery collapsed" in workview
 */
export const VIDEO_TIMELINE_GALLERY_COLLAPSED = 'videoTimelineGalleryCollapsed'

/**
 * Key used to store "items gallery collapsed" in workview
 */
export const ITEMS_GALLERY_COLLAPSED = 'itemsGalleryCollapsed'

/**
 * Key used to store "report time range" in workview
 */
export const REPORT_TIME_RANGE = 'reportTimeRange'

/**
 * Key used to store "video timeline frame width" in workview
 */
export const WORKVIEW_VIDEO_FRAME_LINE_WIDTH_KEY = 'workview_video_frame_line_width'
