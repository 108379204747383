import VueIntercom from '@/services/vue-intercom/index'
import { createPinia, PiniaVuePlugin } from 'pinia'
import PortalVue from 'portal-vue'
import Vue from 'vue'
import Router from 'vue-router'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import { initMeasures } from '@/performance/measure'
import { augmentRouter } from '@/router/augmentedRouter'

import App from './App.vue'
import './assets/darwin-icon-font/style.css'
import './assets/styles/global/variables/colorsV3.scss'
import './assets/styles/index.scss'
import clickOutsideDirective from './directives/click-outside'
import inputAutoBlurDirective from './directives/input-auto-blur'
import loadingDirective from './directives/loading'
import './registerServiceWorker'
import router from './router'
import { INTERCOM_APP_ID, isProductionBuild } from './services/config'
import { observeInvalidIntercomIFrames } from './services/intercom'
import { setupSentry } from './services/sentry'
import store from './store'
import { pluralize } from '@/core/utils/pluralize'
import { mark } from '@/performance/mark'
import { APP_INIT } from '@/performance/keys'

mark(APP_INIT)

augmentRouter()

if (INTERCOM_APP_ID) {
  observeInvalidIntercomIFrames()
}

setupSentry(Vue, router)

Vue.use(PortalVue)
Vue.use(VueIntercom, { appId: INTERCOM_APP_ID })
Vue.use(VueVirtualScroller)
Vue.use(PiniaVuePlugin)
Vue.use(Router)
Vue.filter('pluralize', pluralize)

Vue.directive('click-outside', clickOutsideDirective)
Vue.directive('input-auto-blur', inputAutoBlurDirective)
Vue.directive('loading', loadingDirective)

Vue.config.productionTip = false
Vue.config.silent = isProductionBuild
Vue.config.devtools = !isProductionBuild

const pinia = createPinia()

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  pinia,
  template: '<App/>',
})

document.documentElement.setAttribute(
  'data-theme',
  window.localStorage.getItem('appearance') ?? 'light',
)

initMeasures()
