import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

import type { FeaturePayload } from '@/store/types/FeaturePayload'
import type { FeatureName } from '@/store/types/FeaturePayload'

export const useFeatureFlagsStore = defineStore('featureFlags', () => {
  const loaded = ref(false)

  const featureFlags = reactive<Record<FeatureName, boolean>>({
    ASYNC_AUTO_ASSIGNMENT: false,
    ASYNC_BATCH_ACTIONS: false,
    AUTO_LABEL: false,
    CONSENSUS_STAGE_V2_ALL_TOOLS_ENABLED: false,
    EYE_TOOL: false,
    IMAGE_TAG_LOADER: false,
    LEGACY_DICOM: false,
    MED_LIGHT_MODE: false,
    OBLIQUE_PLANES: false,
    MEDICAL_VOLUME_CACHE: false,
    SYNC_DICOM_PLAYBACK: false,
    DYNAMIC_MPR: false,
    MED_3D_VIEWER: false,
    SHOW_NON_DEFAULT_V1_TEMPLATE_FF: false,
    SIMPLE_TABLE: false,
    TEST_FEATURE: false,
    TEST_FEATURE_2: false,
    WORKSPACE_PRICING: false,
    _AUTOLABEL_SUPERADMIN: false,
    LONG_VIDEOS: false,
    SENTRY_REPLAY: false,
    USE_IMG_RENDERING: false,
    WORKLOG_V3_ENABLED: false,
    FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION: false,
    REPORTS_V3_DASHBOARD: false,
    REPORTS_V1_DISABLED: false,
    ANNOTATIONS_PACKAGE: false,
    ENABLE_DEV_CONFIGS: false,
    WORKVIEW_COMPROMISES_TO_BOOST_PERFORMANCE: false,
    ANNOTATIONS_PAGINATION: false,
    AUTOTRACK_OBB_OUTPUT: false,
    AUTOTRACK_SKIP_FRAMES: false,
    PARALLEL_BLIND_STAGE: false,
    AUDIO_WAVE: false,
    ITEM_PROPERTIES_IN_LOGIC_STAGE: false,
  })

  const setFeatures = (newFeatures: FeaturePayload[]): void => {
    newFeatures.forEach((f) => {
      featureFlags[f.name] = f.enabled
    })
    loaded.value = true
  }

  return { setFeatures, featureFlags, loaded }
})
