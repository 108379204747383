import type { Editor } from '@/modules/Editor/editor'
import { DicomView } from '@/modules/Editor/views/dicomView'
import { ImageView } from '@/modules/Editor/views/imageView'
import { LegacyDicomView } from '@/modules/Editor/views/legacyDicomView'
import { PdfView } from '@/modules/Editor/views/pdfView'
import { StreamView } from '@/modules/Editor/views/streamView'
import { TiledView } from '@/modules/Editor/views/tiledView'
import { VideoView } from '@/modules/Editor/views/videoView'
import type { View } from '@/modules/Editor/views/view'

// eslint-disable-next-line boundaries/element-types
import { DatasetItemType } from '@/store/types'

// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'

// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'

type ViewType =
  | typeof ImageView
  | typeof VideoView
  | typeof StreamView
  | typeof DicomView
  | typeof LegacyDicomView
  | typeof TiledView
  | typeof PdfView

/**
 * Manage the relationship between item and View implementations.
 */
export class ViewCreator {
  private editor: Editor

  constructor(editor: Editor) {
    this.editor = editor
  }

  private getConstructorByType(type: DatasetItemType, file: V2DatasetItemSlot): ViewType {
    switch (type) {
      case 'pdf':
        return PdfView
      case 'image':
        if (file.metadata?.levels) {
          return TiledView
        }

        return ImageView
      case 'video': {
        if (file.streamable) {
          return StreamView
        }
        return VideoView
      }
      case 'dicom':
        if (this.editor.featureFlags.LEGACY_DICOM) {
          // Use the old DICOM experience, for legacy customers not using DICOM for
          // its intended purpose. See the viewport for details.
          return LegacyDicomView
        }

        return DicomView
      default:
        throw new TypeError("Can't handle view type!")
    }
  }

  createForType(file: V2DatasetItemSlot, item: V2DatasetItemPayload, frameIndex: number): View {
    return new (this.getConstructorByType(file.type || DatasetItemType.image, file))(
      this.editor,
      file,
      item,
      frameIndex,
      { hardwareConcurrency: this.editor.hardwareConcurrency },
    )
  }
}
